import { StaticImage } from 'gatsby-plugin-image';
import React from 'react';
import { useTranslation } from 'react-i18next';

import abstractShapes10Svg from '../assets/svg/components/abstract-shapes-10.svg';
import dot1Svg from '../assets/svg/components/dots-1.svg';
import ipadHorizontalSvg from '../assets/svg/components/ipad-horizontal.svg';
import ipadSvg from '../assets/svg/components/ipad.svg';
import iphoneX from '../assets/svg/components/iphone-x.svg';
import macbookSvg from '../assets/svg/components/macbook.svg';
import LocalizedLink from '../components/LocalizedLink';
import SEO from '../components/Seo';
import SocialProof from '../components/SocialProof/SocialProof';
import SVG from '../components/Svg';

const CaseStudiesPage = () => {
  const { i18n, t } = useTranslation();

  return (
    <>
      <SEO
        title={t('meta.caseStudies.title')}
        lang={i18n.language}
        description={t('meta.caseStudies.description')}
      />

      <div className="gradient-y-gray position-relative">
        <div className="space-top-3 space-bottom-2 space-bottom-lg-3">
          <div className="container mt-lg-5">
            <div className="row">
              <div className="col-lg-5 col-xl-6">
                <div className="mb-4">
                  <h1 className="display-4">{t('navigation.caseStudies')}</h1>
                  <p>{t('caseStudies.subtitle')}</p>
                </div>

                <div>
                  <LocalizedLink to="/contact" className="btn btn-primary transition-3d-hover">
                    {t('label.getInTouch')}
                  </LocalizedLink>
                </div>
              </div>
            </div>
          </div>

          <div className="transform-rotate-5">
            <div
              className="d-none d-lg-flex flex-lg-wrap align-items-lg-end position-absolute top-0 left-50 transform-rotate-6"
              style={{ width: '45rem' }}
            >
              <div
                className="device device-horizontal-ipad mr-4 mb-4"
                style={{ width: '23rem' }}
                data-aos="fade-up"
                data-aos-delay="150"
                data-aos-offset="-300"
              >
                <img
                  className="device-horizontal-ipad-frame"
                  src={ipadHorizontalSvg}
                  alt="ipad horizontal"
                />
                <StaticImage
                  className="device-horizontal-ipad-screen"
                  src="../assets/images/case-studies/schedulino.png"
                  alt="layout"
                  layout="constrained"
                />
              </div>

              <div
                className="device device-ipad mb-4"
                style={{ width: '19rem' }}
                data-aos="fade-up"
              >
                <img className="device-ipad-frame" src={ipadSvg} alt="iPad" />
                <StaticImage
                  className="device-ipad-screen"
                  src="../assets/images/case-studies/roomex.png"
                  alt="layout"
                  layout="constrained"
                />
              </div>

              <div
                className="device device-iphone-x align-self-start"
                style={{ width: '7rem' }}
                data-aos="fade-up"
                data-aos-delay="250"
                data-aos-offset="-300"
              >
                <img className="device-iphone-x-frame" src={iphoneX} alt="iPhone" />
                <StaticImage
                  className="device-iphone-x-screen"
                  src="../assets/images/case-studies/schedulino_mobile2.png"
                  alt="layout"
                  layout="constrained"
                />
              </div>

              <div
                className="device mr-4"
                style={{ width: '35rem' }}
                data-aos="fade-up"
                data-aos-delay="200"
                data-aos-offset="-300"
              >
                <img className="img-fluid" src={macbookSvg} alt="macbook" />
                <StaticImage
                  className="device-macbook-screen"
                  src="../assets/images/case-studies/bord-gais-energy.png"
                  alt="layout"
                  layout="constrained"
                />
              </div>

              <figure
                className="max-w-23rem w-100 position-absolute bottom-0 left-0 z-index-n1"
                data-aos="fade-up"
                data-aos-delay="300"
                data-aos-offset="-400"
              >
                <div className="mb-n5 ml-n6">
                  <img className="img-fluid" src={abstractShapes10Svg} alt="abstract shape" />
                </div>
              </figure>
            </div>
          </div>
        </div>

        <figure>
          <svg
            preserveAspectRatio="none"
            xmlns="http://www.w3.org/2000/svg"
            x="0px"
            y="0px"
            viewBox="0 0 1920 100.1"
          >
            <path fill="#fff" d="M0,0c0,0,934.4,93.4,1920,0v100.1H0L0,0z" />
          </svg>
        </figure>
      </div>

      <section className="container space-2 space-bottom-lg-3 space-top-lg-4">
        <div className="row justify-content-lg-between align-items-lg-center">
          <div className="col-lg-5 mb-5 mb-lg-0" data-aos="fade-right">
            <h2>Schedulino</h2>
            <p className="font-weight-bold">{t('caseStudies.1.subtitle')}</p>
            <p className="mb-4">{t('caseStudies.1.text')}</p>
            <LocalizedLink
              to="/case-studies/schedulino"
              className="btn btn-primary transition-3d-hover text-capitalize"
            >
              {t('label.seeCaseStudy')}
            </LocalizedLink>
          </div>
          <figure className="col-lg-7 text-center" data-aos="fade-left">
            <StaticImage
              className="img-fluid rounded-pseudo shadow-lg"
              src="../assets/images/case-studies/schedulino.png"
              alt="Schedulino SaaS application"
            />
          </figure>
        </div>
      </section>

      <SVG />

      <section className="bg-light">
        <div className="container space-2 space-lg-3">
          <div className="row justify-content-lg-between align-items-lg-center">
            <div className="col-lg-5 order-lg-2 mb-5 mb-lg-0" data-aos="fade-left">
              <h2>Bord Gáis Energy</h2>
              <p className="font-weight-bold">{t('caseStudies.2.subtitle')}</p>
              <p className="mb-4">{t('caseStudies.2.text')}</p>
              <a
                href="https://www.bordgaisenergy.ie/"
                target="_blank"
                rel="noopener noreferrer"
                className="btn btn-primary transition-3d-hover text-capitalize"
              >
                {t('label.visitWebsite')}
              </a>
            </div>
            <figure className="col-lg-6 order-lg-1 text-center" data-aos="fade-right">
              <StaticImage
                className="img-fluid rounded-pseudo shadow-lg"
                src="../assets/images/case-studies/bord-gais-energy.png"
                alt="Bord Gáis Energy cloud application"
              />
            </figure>
          </div>
        </div>
      </section>

      <SVG type="ShapeBottom" />

      <section className="container space-2 space-lg-3">
        <div className="row justify-content-lg-between align-items-lg-center">
          <div className="col-lg-5 mb-5 mb-lg-0" data-aos="fade-right">
            <h2>Roomex</h2>
            <p className="font-weight-bold">{t('caseStudies.3.subtitle')}</p>
            <p className="mb-4">{t('caseStudies.3.text')}</p>
            <LocalizedLink
              to="/case-studies/roomex"
              className="btn btn-primary transition-3d-hover text-capitalize"
            >
              {t('label.seeCaseStudy')}
            </LocalizedLink>
          </div>
          <figure className="col-lg-7 text-center" data-aos="fade-left">
            <StaticImage
              className="img-fluid rounded-pseudo shadow-lg"
              src="../assets/images/case-studies/roomex.png"
              alt="Roomex B2B travel management platform"
            />
          </figure>
        </div>
      </section>

      <section className="overflow-hidden">
        <div className="container space-2 space-lg-3">
          <div className="position-relative">
            <figure
              className="max-w-15rem w-100 position-absolute top-0 right-0"
              data-aos="fade-down"
            >
              <div className="mt-n5 mr-n5">
                <img className="img-fluid" src={dot1Svg} alt="dot" />
              </div>
            </figure>

            <div className="bg-light text-center rounded-lg p-4 p-md-5">
              <div className="w-md-80 w-lg-50 mx-md-auto">
                <h2>{t('label.buildProjectWithCodutech')}</h2>
                <p>{t('homePage.footer.subtitle')}</p>

                <LocalizedLink to="/contact" className="btn btn-primary transition-3d-hover mt-2">
                  {t('label.getInTouch')}
                </LocalizedLink>
              </div>
            </div>

            <figure
              className="max-w-15rem w-100 position-absolute bottom-0 left-0"
              data-aos="fade-up"
            >
              <div className="mb-n5 ml-n5">
                <img className="img-fluid" src={dot1Svg} alt="dot" />
              </div>
            </figure>
          </div>
        </div>
      </section>

      <section className="container space-top-1 space-bottom-2 space-bottom-lg-3">
        <SocialProof showAll />
      </section>
    </>
  );
};

export default CaseStudiesPage;
